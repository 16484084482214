import React from "react";
import './contact.scss';

function Contact(){
    return (<div className="contact">
        <h2>Contact Me</h2>
        <p>Like my Work?</p>
        <p> Want to get in touch with me? Check out my <a href="linkedin.com/in/arunmishra19" target="_blank">Linkedin Profile</a></p> 
        <p> Want to implement that IDEA you always had in your head? Or want to take your product to the next level?</p>
        <p>Reach out to me on <a href="mailto:arun.mishra.0019@gmail.com">arun.mishra.0019@gmail.com</a></p>
    </div>)
}

export default Contact