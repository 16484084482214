import React from 'react';
import './App.css';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Banner from './components/banner/banner';
import About from './components/about/about';
import Services from './components/services/services';
import Contact from './components/contact/contact';
import Skills from './components/Skills/skills';
import Works from './components/works/works';
  
function App() {
    return (
        <div className="wrapper">
           <Banner />
            <About />
            <Skills />
            <Works />
            <Contact />
           {/* <Header /> */}
           {/* <Footer />  */}
        </div>
    )
}
  
export default App
