import React from "react";
import './banner.scss';
import Logo from "../logo/logo";
import sunset1 from '../../assets/images/sunset1.jpg';
import sunset2 from '../../assets/images/sunset3-min.png';
import sun from '../../assets/images/sun-min.png';
import useWindowSize from "use-window-size-v2";

function Banner(){
    const { width, height } = useWindowSize(100); // wait 100ms for the resize events
    let x = parseInt([width.toString().replace('px','')])<(height/2)*3.555?((height/2)*3.555 - width)*0.9:'unset';
    // let z = ((height/2)*3.555);
    // let y = '-'+(height*0.535);
    return (
        <>
            {/* <video className="background-video" playsInline autoPlay muted loop>
                <source src={videoSrc} type="video/mp4" />
            </video> */}
           
            <div className="background">
            <img src={sunset1} className="sunset1" style={{right:x,width:x=='unset'?width:''}}/>
           
            <img src={sunset2} className="sunset2" style={{right:x,width:x=='unset'?width:''}}/>
            <div className="container">
  <div className="sky"></div>
  <div className="light"></div></div>

  <div className="sun">
    <img src={sun} />
  </div>
  <div className="bird1"></div>
  <div className="bird1 b2"></div>
  <div className="bird1 b3"></div>
  <div className="bird1 b4"></div>
  <div className="birdr1"></div>
  <div className="bird"></div>
  <div className="birdr"></div>
</div>
            {/* <img src={mountain} className="mountain" /> */}
                {/* <img src={sky} className="sky" />
                <img src={mountains} className="mountains" />
                <img src={forest} className="forest" />
                <img src={river} className="river" /> */}

            {/* </div> */}
            <div className="logo-panel">
                <Logo />
                <span className="subtitle">The Developer you always needed</span>
            </div>
            {/* <img className="boat" src={boatSrc} /> */}
        </>
        
    )
}

export default Banner