import React from "react";
import './works.scss';
import imageSrc from '../../assets/images/banner2.png' 
import Portfolio from "../portfolio/portfolio";
const projects = [
    {
        title: "AI Portal for processing Books",
        description : "A publisher wanted more insight into their books and wanted something futuristic. They had built a platform to analyze and deep search the contents of the books and needed an awesome and clean UI.",
        images : [
            {
              original: "/works/Compendium/1.JPG",
              thumbnail: "/works/Compendium/1.JPG",
            }
          ],
        websiteLink : "/static/sources/Compendium/login.html"
    },
    {
        title: "Real Estate Portal Landing Page ",
        description : "Just a landing page for an existing portal which helped users identify properties available in their selected area",
        images : [
            {
              original: "/works/hostel/1.JPG",
              thumbnail: "/works/hostel/1.JPG",
            },
            {
              original: "/works/hostel/2.JPG",
              thumbnail: "/works/hostel/2.JPG",
            },
            {
              original: "/works/hostel/3.JPG",
              thumbnail: "/works/hostel/3.JPG",
            }
          ],
        websiteLink : "/static/sources/hostel/index.html"
    },
    {
        title: "Online Nursery Ecommerce Site",
        description : "An online ecommerce site for buying and selling plants online.",
        images : [
          {
            original: "/works/nursery/1.JPG",
            thumbnail: "/works/nursery/1.JPG",
          },
          {
            original: "/works/nursery/2.JPG",
            thumbnail: "/works/nursery/2.JPG",
          }
          ],
        websiteLink : "/static/sources/nursery/index.html"
    },
    
    {
        title: "Veternary",
        description : "A website for a Veterinary Hospital",
        images : [
          {
            original: "/works/pet/1.JPG",
            thumbnail: "/works/pet/1.JPG",
          },
          {
            original: "/works/pet/2.JPG",
            thumbnail: "/works/pet/2.JPG",
          },
          {
            original: "/works/pet/3.JPG",
            thumbnail: "/works/pet/3.JPG",
          },
          ],
        websiteLink : "/static/sources/vet/index.html"
    },
    {
        title: "Task Manager",
        description : "Essentially a custom Kanban Board which was used by an organization to keep track of their activities",
        images : [
          {
            original: "/works/task-manager/app4.jpg",
            thumbnail: "/works/task-manager/app4.jpg",
          },
          {
            original: "/works/task-manager/app5.jpg",
            thumbnail: "/works/task-manager/app5.jpg",
          }
          ],
        websiteLink : ""
    },
    {
        title: "Courier Delivery Tracking and Analytics Dashboard",
        description : "A portal which was used by a courier organization. They wanted somthing that can be used to keep track of their couriers delivery service along with analytics of how many got delayed, postponed or canceled",
        images : [
          {
            original: "/works/Analytics/app1.jpg",
            thumbnail: "/works/Analytics/app2.jpg",
          },
          ],
        websiteLink : ""
    },
    {
        title: "Some Isolated Screens",
        description : "A few screens that were build for various clients",
        images : [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/",
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/",
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/",
            },
          ],
        websiteLink : ""
    },
    {
        title: "Custom Icons",
        description : "I also build custom icons based on requirements. Some of them are here",
        images : [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/",
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/",
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/",
            },
          ],
        websiteLink : ""
    },
    {
        title: "CSS Based Loaders",
        description : "Custom css loaders that I have built for clients as per their specifications",
        images : [
            {
              original: "https://picsum.photos/id/1018/1000/600/",
              thumbnail: "https://picsum.photos/id/1018/250/150/",
  
            },
            {
              original: "https://picsum.photos/id/1015/1000/600/",
              thumbnail: "https://picsum.photos/id/1015/250/150/",
            },
            {
              original: "https://picsum.photos/id/1019/1000/600/",
              thumbnail: "https://picsum.photos/id/1019/250/150/",
            },
          ],
        websiteLink : ""
    }
]

function Works(){
    
    return (<div className="works">
         <h2>My Works</h2>
         <p>I have worked in startups, MNCs and with individual people / organizations as Freelancer. The diversity of work at each environment
            has helped me become the best at what I do. A good UX to me is something that has mostly identified problems an user may face while using 
            an application and patterns in which an User will use the apps even before the development starts.
        </p>
        <p>
            The unique combination of UX/UI Designing along with Development capabilities enables me to shorten the entire project development time 
            as I identify issues in early stages of project.
        </p>
        <p>
            Rest assured, I come up with unique solutions to make the User Journey as smooth and enjoyable as possible.
        </p>
        <p>
            I make sure that the Designer me never stops challenging the Developer me.
        </p>
        <p>
            Here are few of my works apart from the ones I signed a NDA
        </p>
         <div className="portfolio">
            {   
                projects.map((item) =>
                <Portfolio key={item.title} title={item.title} imageSrc={item.imageSrc} images={item.images} description={item.description} websiteLink={item.websiteLink}/>
               )
            }
            
          
         </div>


    </div>)
}

export default Works