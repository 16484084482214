import React from "react";
import './about.scss';

function About(){
    return (
        <div className="about">
            <p>
            "A software product is much more than just a graceful display of information along with functionalities. 
            The User Interface should have a story of its own. It should make the user feel comfortable as if the application is their own home.
            And the backend should be so optimized that the user doesn't get a chance to deviate from their intended use"
            </p>
            <p>
                - Me
            </p>
            <h2>Who am I?</h2>
            <p>
            A passionate Full Stack Developer specializing in User Interfaces, UX Designs, UI Development, Mobile App 
                Development and Backend Development with an experience of over 10 years.

            </p>
            
        </div>
    )
}

export default About