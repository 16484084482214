import React from "react";
import './logo.scss';
import logoSrc from '../../assets/logo/origami-svgrepo-com.svg'

const text = 'ARUN';

function Logo(){
    return (
        <div className="logo red">
            <img src={logoSrc} alt={text}/>
            <span>{text}</span>
        </div>
    )
}

export default Logo