import React from "react";
import './skills.scss';
import ParticleBackground from '../particle-background/particle';

function Skills(){

    return (<div className="skills">
    <div className="particles">
        <ParticleBackground />
    </div>
    <h2>My SKILLS</h2>
    
   
    <div className="table">
    <div>
            <h3>UX/UI Design Tools</h3>
            <p>Figma, Adobe Photoshop, Adobe xD, Balsimiq</p>
        </div>
        <div>
            <h3>Javascript Libraries</h3>
            <p>Angular, Reactjs, VueJs, NextJs, React Native, Ionic Framework, Angular Material, Ng Zorro</p>
        </div>
        <div>
            <h3>CSS</h3>
            <p>CSS3, SCSS, Tailwind</p>
        </div>
        <div>
            <h3>Charts</h3>
            <p>Chartjs, D3</p>
        </div>
        <div>
            <h3>Backend</h3>
            <p>Nodejs, Python</p>
        </div>
        <div>
            <h3>Database</h3>
            <p>MySQL, PostGress, MongoDb</p>
        </div>
       
           </div>
           </div>)
}

export default Skills