import React, { useRef } from "react";
import './portfolio.scss';
import ImageGallery from "react-image-gallery";

function Portfolio(props){
    const gallery = useRef(null)

    function openGallery(){
        gallery.current.toggleFullScreen();
        console.log(gallery);
    }
    function openSite(){
        console.log(props.websiteLink);
        window.open(props.websiteLink, "_blank");
    }
    return (
        <div className="portfolio">
        <h5>{props.title}</h5>
        <div className="section">
            
            <div class="thumbnail">
                <ImageGallery ref={gallery} items={props.images} showThumbnails={false} showNav={true} showPlayButton={false} autoPlay={true}/>
                
            </div>
            <div class="description">
                 {props.description}
                 <div class="buttons">
                    <button className="view-images" onClick={() => openGallery()}>View Images</button>
                    {props.websiteLink?(<button className="view-site" onClick={() =>openSite()}>View Site</button>):('')}
                </div>
            </div>
        </div>
     </div>

    )
}



export default Portfolio